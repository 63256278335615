import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

const CasesCard = ({ cases }) => {
    return (
      <div className="sm:max-w-md overflow-hidden border-b border-gray-600">
{        <Link to={cases.fields.slug}>
          <CasesImage image={cases.frontmatter.image} />
          <div className="px-6 py-2">
            <div className="font-bold text-xl mb-2">
              {cases.frontmatter.name}
            </div>
            <p className="text-gray-700 text-base">
              {cases.frontmatter.description}
            </p>
          </div>
        </Link>}
      </div>
    )
}

const CasesImage = ({ image }) => {
    const imageStyle = {
      width: "100%",
      height: 200,
      overflow: "hidden",
    }
    return <Img style={imageStyle} fluid={image.childImageSharp.fluid} />
}

const CasesList = ({ data }) => {
    const { edges: casestudies } = data.allMarkdownRemark
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {casestudies &&
            casestudies.map(({ node: cases }) => (
                <CasesCard key={cases.id} cases={cases} />
            ))}
        </div>

    )
}
export default () => (
    <StaticQuery
      query={graphql`
        {
          allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "cases-page" } } }
          ) {
            edges {
              node {
                id
                html
                fields {
                  slug
                }
                frontmatter {
                  name
                  description
                  image {
                    id
                    childImageSharp {
                      fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid_tracedSVG
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <CasesList data={data} />}
    ></StaticQuery>
  )