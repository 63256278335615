import React from "react"

import Layout from "../../components/layout"
import CasesList from "../../components/CasesList"

const CasesPage = () => {
  return (
    <Layout>
      <CasesList />
    </Layout>
/*     <div>
        <h2>list of case studies</h2>
    </div> */
  )
}

export default CasesPage